<template>
  <div id="theSignatureDocLink" class="vld-parent">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-if="!initialLoad" id="main-info">
      <div v-if="guidDetails.requiresTwoFactorCode" id="need-access-token-div" class="guid-info">
        <div class="auth-div">
          <VasionLogo id="logo" class="extra-bottom-margin" />
          <VasionInput
            id="two-factor-auth-input"
            v-model="authCode"
            class="input-style"          
            inputType="top-white"
            title="Two Factor Authentication Code"
          />

          <VasionButton
            id="auth-code-ok-button"
            :classProp="'primary'"
            :isDisabled="disableOKAuthButton"
            @vasionButtonClicked="authCodeOKClick()"
          >
            OK
          </VasionButton>

          <VasionButton
            id="resend-button"
            :classProp="'primary-light'"
            @vasionButtonClicked="resendAuthCode()"
          >
            Resend Code
          </VasionButton>
        </div>
      </div>
      <div v-else-if="guidDetails.requiresUsernameAndPassword" id="need-username-password-div" class="guid-info">
        <div class="auth-div">
          <VasionLogo id="logo" class="extra-bottom-margin" />

          <VasionInput
            id="username-input"
            v-model="username"
            class="input-style extra-bottom-margin"          
            inputType="top-white"
            title="Username"
          />

          <VasionInput
            id="password-input"
            v-model="password"
            class="input-style extra-bottom-margin"          
            inputType="top-white"
            title="Password"
            type="password"
          />

          <VasionButton
            id="login-ok-button"
            :classProp="'primary'"
            :isDisabled="disableLoginButton"
            @vasionButtonClicked="loginOKClick()"
          >
            OK
          </VasionButton>
        </div>
      </div>
      <div v-else-if="!guidDetails.Token" id="no-data" class="guid-info">
        <VasionBadLinkIcon id="no-data-icon" />
        <h1>GUID is not valid.</h1>
      </div>
      <div v-else-if="guidDetails.guidIsExpired && guidDetails.documentAssignedToUser" id="expired-guid" class="guid-info">
        <VasionBadLinkIcon id="no-data-icon" />
        <div class="vasion-page-title-black">
          Your link has expired.  Please check for a new one.
        </div>
        <div class="vasion-page-subheader small-top-margin">
          Protecting your data is out top priority. To keep your document secure, we've emailed you a new link. Learn more about security at Vasion on our <a href="https://www.printerlogic.com/technical-support/">support site</a>.
        </div>
        <div class="link-div">
          <a href="#" @click="sendNewGUIDLink">Didn't receive a new email?</a>
        </div>
      </div>
      <div v-else-if="guidDetails.guidIsExpired && !guidDetails.documentAssignedToUser" id="expired-guid" class="guid-info">
        <VasionBadLinkIcon id="no-data-icon" />
        <div class="vasion-page-title-black">
          Your Signature Request has been Completed.
        </div>
      </div>
    </div>

    <VasionSnackbar
      id="signature-document-link-guid-snackbar"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'TheSignatureDocLink',
  components: {
    Loading,
  },
  data: function () {
    return {
      authCode: '',
      guidDetails: {},
      isLoading: true,
      initialLoad: true,
      loaderBackgroundColor,
      loaderColor,
      password: '',
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      username: '',
    }
  },
  computed: {
    disableLoginButton() { return !this.username || !this.password },
    disableOKAuthButton() { return !this.authCode || this.authCode.length !== 6 },    
    isSignedIn() { return this.$store.state.common.isSignedIn },
  },
  async beforeCreate() {
    if (this.isSignedIn) {
      await this.$store.dispatch('common/signOut')
    }
  },
  async created() {
    await this.attemptAuth()
    this.initialLoad = false
  },
  methods: {
    async attemptAuth() {
      this.isLoading = true

      const payload = {
        password: this.password,
        signatureDocLinkGUID: this.$route.params.signatureDocumentLinkGUID,
        twoFactorAuthCode: this.authCode,
        username: this.username,
      }

      this.guidDetails = await this.$store.dispatch('security/getSignatureLinkGUIDDetails', payload)
      if (this.guidDetails?.Token && !this.guidDetails.guidIsExpired) {
        await this.$router.push({ name: 'Document', params: { documentID: this.guidDetails.documentID } })
      }
      this.isLoading = false
    },
    async authCodeOKClick() {
      await this.attemptAuth()

      if (this.guidDetails.requiresTwoFactorCode) {
        this.snackbarTitle = 'Invalid Authentication Code'
        this.snackbarImage = false        
        this.showSnackbar = true
      }
    },
    async loginOKClick() {
      await this.attemptAuth()

      if (this.guidDetails.requiresUsernameAndPassword) {
        this.password = ''
        this.snackbarTitle = 'Invalid Credentials'
        this.snackbarImage = false        
        this.showSnackbar = true
      }
    },
    async resendAuthCode() {
      const payload = {
        Value: this.$route.params.signatureDocumentLinkGUID,
      }

      this.isLoading = true
      const result = await this.$store.dispatch('security/generateSigDocLinkGUIDAuthCode', payload)

      if (result) {
        this.snackbarTitle = 'New Code Sent!'
        this.snackbarImage = true
      } else {
        this.snackbarTitle = 'Unable to Send Code'
        this.snackbarImage = false
      }

      this.isLoading = false
      this.showSnackbar = true
    },
    sendNewGUIDLink() {
      if (this.guidDetails && this.guidDetails.documentID !== undefined && this.guidDetails.documentID > 0) {
        this.snackbarTitle = 'Email Sent!'
        this.snackbarImage = true
        this.$store.dispatch('document/sendNewSignatureDocumentEmail', this.guidDetails.documentID)
      } else {
        this.snackbarTitle = 'Unable to Send Email'
        this.snackbarImage = false
      }

      this.showSnackbar = true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #main-info {
    height: calc(100vh - 55px);
    width: 100vw;
    background: $grey-50;
    margin-top: -20px;
    text-align: center;
  }

  .auth-div {
    padding-top: 100px;
    width: 350px;
    margin: 0 auto;
    position: relative;
  }

  .guid-info {
    height: calc(100vh - 55px);
    text-align: center;
    max-width: 765px;
    margin: 0 auto;
  }

 .small-top-margin {
    margin-top: 12px;
  }

  #no-data-icon {
    fill: $grey-400;
    margin-top: 150px;
    margin-bottom: 24px;
  }

  .link-div {
    margin-top: 24px;
  }

  a {
    color: #ff5a00 !important;
    font-size: 20px;
  }

  .extra-bottom-margin {
    margin-bottom: 10px;
  }
  
</style>
